<template>
  <div>
    <div id="su-newuser-page">
      <div class="flex-column">
        <div class="flex-row">
          <h1 class="h1-title">New User</h1>
          <button class="no-button-style margin-left-auto margin-right pointer-cursor" v-on:click="goToLogout()">
            Logout
          </button>
        </div>
        <div class="flex-row">
          <div class="flex-column flex-grow">
            <h1 class="h1 margin-top">New User</h1>
            <div class="flex-row margin-top">
              <tiny-button
                :selected="newUserMode == 0"
                class="margin-right"
                title="New Dealer"
                v-on:click="newUserMode = 0"
              />
              <tiny-button
                :selected="newUserMode == 1"
                class="margin-right"
                title="Add to Dealer"
                v-on:click="newUserMode = 1"
              />
              <tiny-button :selected="newUserMode == 2" title="New Wholesaler" v-on:click="newUserMode = 2" />
            </div>
            <form @submit.prevent="submit" v-if="newUserMode == 0" class="flex-column">
              <label class="label margin-top">Dealership ID</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="number"
                v-model="newUserDict.dealership"
                placeholder="dealership"
              />
              <label class="label margin-top-05">First Name</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newUserDict.first_name"
                placeholder="first_name"
              />
              <label class="label margin-top-05">Last Name</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newUserDict.last_name"
                placeholder="last_name"
              />
              <label class="label margin-top-05">Email</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newUserDict.email"
                placeholder="email"
              />
              <label class="label margin-top-05">Phone Number</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newUserDict.phone_number"
                placeholder="phone_number"
              />
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_manager" />
                <label class="label margin-left-025 vertical-flex-center">is_manager</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_subscribed_for_emails" />
                <label class="label margin-left-025 vertical-flex-center">is_subscribed_for_emails</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_subscribed_for_all_offer_notifications" />
                <label class="label margin-left-025 vertical-flex-center"
                  >is_subscribed_for_all_offer_notifications</label
                >
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_web_form_creation_access" />
                <label class="label margin-left-025 vertical-flex-center">has_web_form_creation_access</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.can_request_offers_even_if_sales" />
                <label class="label margin-left-025 vertical-flex-center">can_request_offers_even_if_sales</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_marketing_access" />
                <label class="label margin-left-025 vertical-flex-center">has_marketing_access</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_marketing_manager" />
                <label class="label margin-left-025 vertical-flex-center">is_marketing_manager</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_subscribed_for_marketing_response_emails" />
                <label class="label margin-left-025 vertical-flex-center"
                  >is_subscribed_for_marketing_response_emails</label
                >
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_subscribed_for_marketing_response_texts" />
                <label class="label margin-left-025 vertical-flex-center"
                  >is_subscribed_for_marketing_response_texts</label
                >
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_subscribed_for_marketing_response_pushes" />
                <label class="label margin-left-025 vertical-flex-center"
                  >is_subscribed_for_marketing_response_pushes</label
                >
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_takata_approved" />
                <label class="label margin-left-025 vertical-flex-center">is_takata_approved</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_blocked_from_offers" />
                <label class="label margin-left-025 vertical-flex-center">is_blocked_from_offers</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.can_request_ppsr" />
                <label class="label margin-left-025 vertical-flex-center">can_request_ppsr</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_autograb_access" />
                <label class="label margin-left-025 vertical-flex-center">has_autograb_access</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_autograb_vr_access" />
                <label class="label margin-left-025 vertical-flex-center">has_autograb_vr_access</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.is_autograb_factory_options_account" />
                <label class="label margin-left-025 vertical-flex-center">is_autograb_factory_options_account</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_sourcing_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_sourcing_permission</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_pricing_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_pricing_permission</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_recapture_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_recapture_permission</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_wholesale_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_wholesale_permission</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_stock_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_stock_permission</label>
              </div>
              <div class="flex-row margin-top-05">
                <input type="checkbox" v-model="newUserDict.has_ag_fleet_permission" />
                <label class="label margin-left-025 vertical-flex-center">has_ag_fleet_permission</label>
              </div>

              <label class="label margin-top-05">Rev Sales Code</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.rev_sales_code"
                placeholder="rev_sales_code"
              />

              <label class="label margin-top-05">Units Contact Code</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.units_contact_code"
                placeholder="units_contact_code"
              />

              <label class="label margin-top-05">PMDS Contact Code</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.pmds_contact_code"
                placeholder="pmds_contact_code"
              />

              <label class="label margin-top-05">EGM Lead Dealer Code</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.egm_lead_dealer_code"
                placeholder="egm_lead_dealer_code"
              />

              <label class="label margin-top-05">Erapower User ID</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.erapower_user_id"
                placeholder="erapower_user_id"
              />

              <label class="label margin-top-05">Erapower Salesman Number</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.erapower_salesman_no"
                placeholder="erapower_salesman_no"
              />

              <label class="label margin-top-05">Erapower Sales Type</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.erapower_sales_type"
                placeholder="erapower_sales_type"
              />

              <label class="label margin-top-05">Titan Salesperson ID</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                type="text"
                v-model="newUserDict.titan_salesperson_id"
                placeholder="titan_salesperson_id"
              />
              <primary-button :title="submitting ? 'Submitting...' : 'Submit'" class="margin-top-025" />
            </form>
            <form @submit.prevent="submitLogin" v-if="newUserMode == 1" class="flex-column">
              <label class="label margin-top">Dealer ID</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="number"
                v-model="newUserLoginDict.dealer"
                placeholder="dealer"
              />
              <label class="label margin-top-05">Username</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newUserLoginDict.username"
                placeholder="username"
              />
              <primary-button :title="submitting ? 'Submitting...' : 'Submit'" class="margin-top-025" />
            </form>
            <form @submit.prevent="submitReseller" v-if="newUserMode == 2" class="flex-column">
              <label class="label margin-top">Username</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newResellerDict.username"
                placeholder="username"
              />
              <label class="label margin-top">First Name</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newResellerDict.first_name"
                placeholder="first_name"
              />
              <label class="label margin-top">Last Name</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newResellerDict.last_name"
                placeholder="last_name"
              />
              <label class="label margin-top">Email</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newResellerDict.email"
                placeholder="email"
              />
              <label class="label margin-top">Phone Number</label>
              <input
                class="text-field margin-top-025 fix-placeholder-color"
                required
                type="text"
                v-model="newResellerDict.phone_number"
                placeholder="phone_number"
              />
              <div class="flex-row">
                <input
                  class="text-field margin-top-025 margin-right-05 fix-placeholder-color"
                  type="checkbox"
                  v-model="newResellerDict.requires_payments"
                  placeholder="requires_payments"
                />
                <label class="label margin-top">Requires Payments</label>
              </div>

              <primary-button :title="submitting ? 'Submitting...' : 'Submit'" class="margin-top-025" />
            </form>
          </div>
          <div class="flex-grow flex-column padding-left padding-right">
            <h1 class="h1">Pending Alert</h1>
            <primary-button title="Refresh" v-on:click="refreshDealers" class="margin-top-025 margin-left-auto" />
            <table class="results-table margin-top">
              <tr class="header">
                <th>Dealer Name</th>
                <th>Dealership</th>
                <th>Alert</th>
              </tr>
              <tr v-for="dealer in dealers" v-bind:key="dealer.id" class="data-row">
                <td>{{ dealer.first_name }} {{ dealer.last_name }}</td>
                <td>{{ dealer.dealership.name }}</td>
                <td>
                  <primary-button :title="dealer.alerting ? 'Alerting' : 'Alert'" v-on:click="alertDealer(dealer)" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <error-modal v-if="error" :errors="[error]" @cancel="error = null" @cancelAll="error = null" />
  </div>
</template>

<script>
import PrimaryButton from "../../components/Buttons/v2/PrimaryButton.vue";
import TinyButton from "../../components/Buttons/v2/TinyButton.vue";
import ErrorModal from "../../components/Modals/v2/ErrorModal.vue";
import {
  createIndividualDealer,
  createIndividualDealerLogin,
  getUnalertedDealer,
  alertIndividualDealer
} from "../../api/Dealer.js";
import { createIndividualReseller } from "../../api/Reseller.js";

export default {
  name: "SuperUserGeorgeReport",
  components: { PrimaryButton, TinyButton, ErrorModal },
  data: function () {
    return {
      submitting: false,
      dealers: [],
      error: null,
      newUserMode: 0,
      newUserLoginDict: {
        dealer: "",
        username: ""
      },
      newUserDict: {
        dealership: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        is_manager: false,
        is_subscribed_for_emails: false,
        is_subscribed_for_all_offer_notifications: false,
        has_web_form_creation_access: false,
        can_request_offers_even_if_sales: false,
        has_marketing_access: false,
        is_marketing_manager: false,
        is_subscribed_for_marketing_response_emails: false,
        is_subscribed_for_marketing_response_texts: false,
        is_subscribed_for_marketing_response_pushes: false,
        is_takata_approved: false,
        is_blocked_from_offers: false,
        can_request_ppsr: false,
        has_autograb_access: false,
        has_autograb_vr_access: false,
        is_autograb_factory_options_account: false,
        has_ag_sourcing_permission: false,
        has_ag_pricing_permission: false,
        has_ag_recapture_permission: false,
        has_ag_wholesale_permission: false,
        has_ag_stock_permission: false,
        has_ag_fleet_permission: false,
        rev_sales_code: "",
        units_contact_code: "",
        pmds_contact_code: "",
        egm_lead_dealer_code: "",
        erapower_user_id: "",
        erapower_salesman_no: "",
        erapower_sales_type: "",
        titan_salesperson_id: ""
      },
      newResellerDict: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        requires_payments: false
      }
    };
  },
  methods: {
    submit: function () {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      createIndividualDealer(this.newUserDict)
        .then(() => {
          this.submitting = false;
          this.newUserDict.dealership = "";
          this.newUserDict.first_name = "";
          this.newUserDict.last_name = "";
          this.newUserDict.email = "";
          this.newUserDict.phone_number = "";
          this.newUserDict.is_manager = false;
          this.newUserDict.is_subscribed_for_emails = false;
          this.newUserDict.is_subscribed_for_all_offer_notifications = false;
          this.newUserDict.has_web_form_creation_access = false;
          this.newUserDict.can_request_offers_even_if_sales = false;
          this.newUserDict.has_marketing_access = false;
          this.newUserDict.is_marketing_manager = false;
          this.newUserDict.is_subscribed_for_marketing_response_emails = false;
          this.newUserDict.is_subscribed_for_marketing_response_texts = false;
          this.newUserDict.is_subscribed_for_marketing_response_pushes = false;
          this.newUserDict.is_takata_approved = false;
          this.newUserDict.is_blocked_from_offers = false;
          this.newUserDict.can_request_ppsr = false;
          this.newUserDict.has_autograb_access = false;
          this.newUserDict.has_autograb_vr_access = false;
          this.newUserDict.is_autograb_factory_options_account = false;
          this.newUserDict.has_ag_sourcing_permission = false;
          this.newUserDict.has_ag_pricing_permission = false;
          this.newUserDict.has_ag_recapture_permission = false;
          this.newUserDict.has_ag_wholesale_permission = false;
          this.newUserDict.has_ag_stock_permission = false;
          this.newUserDict.has_ag_fleet_permission = false;
          this.newUserDict.rev_sales_code = "";
          this.newUserDict.units_contact_code = "";
          this.newUserDict.pmds_contact_code = "";
          this.newUserDict.egm_lead_dealer_code = "";
          this.newUserDict.erapower_user_id = "";
          this.newUserDict.erapower_salesman_no = "";
          this.newUserDict.erapower_sales_type = "";
          this.newUserDict.titan_salesperson_id = "";
        })
        .catch(error => {
          console.log(error.response);
          this.submitting = false;
          this.error = error;
        });
    },
    submitLogin: function () {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      createIndividualDealerLogin(this.newUserLoginDict)
        .then(() => {
          this.submitting = false;
          this.newUserLoginDict.dealer = "";
          this.newUserLoginDict.username = "";
        })
        .catch(error => {
          console.log(error.response);
          this.submitting = false;
          this.error = error;
        });
    },
    submitReseller: function () {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      createIndividualReseller(this.newResellerDict)
        .then(() => {
          this.submitting = false;
          this.newResellerDict.username = "";
          this.newResellerDict.first_name = "";
          this.newResellerDict.last_name = "";
          this.newResellerDict.email = "";
          this.newResellerDict.phone_number = "";
          this.newResellerDict.requires_payments = false;
        })
        .catch(error => {
          this.submitting = false;
          console.log(error.response);
          this.error = error;
        });
    },
    refreshDealers: function () {
      getUnalertedDealer()
        .then(response => {
          this.dealers = response;
        })
        .catch(error => {
          console.log(error.response);
          this.error = error;
        });
    },
    alertDealer(dealer) {
      if (dealer.alerting) {
        return;
      }
      dealer.alerting = true;
      alertIndividualDealer(dealer)
        .then(() => {
          dealer.alerting = false;
          this.dealers = this.dealers.filter(d => d.id !== dealer.id);
        })
        .catch(error => {
          console.log(error.response);
          dealer.alerting = false;
          this.error = error;
        });
    },
    goToLogout: function () {
      this.$router.push({ name: "Logout" });
    }
  }
};
</script>

<style lang="scss">
* {
  padding: 0px;
  margin: 0px;
}

// Required for margin reset
p {
  margin: 0px;
}
</style>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#su-newuser-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
  max-width: 100vw;
}
</style>
